<template>
	<div>
		<tap :taps="['中心介绍']" :isMore="false"></tap>
		<div class="content">
			<div class="shareholder">
				<p style="font-size: 16px;" v-html="detail.subject"></p>
			</div>
		</div>
	</div>
</template>

<script>
import Vue from 'vue'
export default {
	data() {
		return {
			detail:{}
		}
	},
	created() {
		this.$api.about.getSubDail(8,this).then(res=>{
			this.detail = res[0]
		})
	},
	methods: {
		clkTap() {

		}
	}
}
</script>

<style lang="less" scoped>

</style>